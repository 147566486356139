<template lang="pug">
canvas.canvas(ref='canvas')
</template>

<script>
window.__DEBUG__ = false;

import debounce from 'lodash/debounce';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import createREGL from 'regl';
import { initTailingCommand } from '@/commands/vooc/tailing/index.js';
import { resize } from '../ImageEffect/common.js';

export default {
  name: 'Tailing',
  setup() {
    const canvas = ref(null);

    let regl;

    onMounted(async () => {
      resize(canvas.value);

      regl = createREGL({ canvas: canvas.value });

      let curPos = [0, 0];
      let prevPos = [0, 0];
      const dpr = window.devicePixelRatio;
      const { start, update } = initTailingCommand(regl, canvas.value);
      start({ curPos, prevPos });

      const onMousemove = debounce((e) => {
        prevPos[0] = curPos[0];
        prevPos[1] = curPos[1];
        curPos[0] = e.clientX;
        curPos[1] = window.innerHeight - e.clientY;
        update({
          curPos: curPos.map((v) => v * dpr),
          prevPos: prevPos.map((v) => v * dpr),
        });
      }, 0);

      window.addEventListener('mousemove', onMousemove);
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas };
  },
};
</script>

<style lang="stylus" scoped>
@import '../ImageEffect/common.styl'
</style>
