import vert from './tailing.vert';
import frag from './tailing.frag';

const N = 10;
const NUM = 800;
const RADIUS = 0.03;

/**
 * @param {number | Array<number>} a
 * @param {number | Array<number>} b
 * @param {number} p
 */
const mix = (a, b, p) => {
  if (Array.isArray(a)) {
    return a.map((_, i) => mix(a[i], b[i], p));
  }
  return a * (1 - p) + b * p;
};

/**
 * @param {ReturnType<createREGL>} regl
 * @param {HTMLCanvasElement} canvas
 */
export const initTailingCommand = (regl, canvas) => {
  const { width, height } = canvas;
  const originPosition = Array(NUM)
    .fill(0)
    .map((_, i) => {
      const angle = Math.random() * 2 * Math.PI;
      const r = RADIUS * Math.sqrt(Math.random()) * Math.random();
      const x = r * Math.cos(angle);
      const y = r * Math.sin(angle);
      return [x * (height / width), y, i / NUM];
    });

  const mousePositions = Array.from(Array(N)).map(() => [0, 0]);
  const displacement = originPosition.map(() => [0, 0, 0]);
  const displacementBuffer = regl.buffer({ data: displacement, usage: 'dynamic', type: 'float' });

  const draw = regl({
    vert,
    frag,
    attributes: {
      // 均为 -1 ~ 1 的值
      a_position: originPosition,
      a_displacement: {
        buffer: displacementBuffer,
      },
    },
    uniforms: {
      u_time: ({ time }) => time,
      u_mousePos: (_, props) => props.curPos,
      u_mousePrevPos: (_, props) => props.prevPos,
      u_canvasRes: [width, height],
      'u_color[0]': [0, 0.5, 0.6],
      'u_color[1]': [0, 0.2, 0.4],
    },
    count: originPosition.length,
    primitive: 'points',
    depth: { enable: false },
    blend: {
      enable: true,
      func: {
        srcRGB: 'src alpha',
        srcAlpha: 'src alpha',
        dstRGB: 'dst alpha',
        dstAlpha: 'dst alpha',
      },
    },
  });

  let updateData = {
    curPos: [0, 0],
    prevPos: [0, 0],
  };

  let mouse = [-1, -1];

  const updateDisplacement = () => {
    mousePositions.shift();
    mousePositions.push(mouse);

    originPosition.forEach((pos, i) => {
      const mouseIndex = Math.trunc(pos[2] * N);
      const prevPos = mousePositions[mouseIndex];
      const curPos = mouseIndex === N - 1 ? prevPos : mousePositions[mouseIndex + 1];

      const p = (pos[2] * N) % 1;
      const mousePos = mix(prevPos, curPos, p);
      const normalizedPos = [(mousePos[0] / width - 0.5) * 2, (mousePos[1] / height - 0.5) * 2, 0];
      displacementBuffer.subdata(normalizedPos, i * 3 * 4);
    });
  };

  const update = (props) => {
    mouse = props.curPos || mouse;
    updateData = Object.assign(updateData, props);
  };

  const start = (props) => {
    update(props);
    regl.frame(() => {
      updateDisplacement();
      draw({
        ...updateData,
      });
    });
  };

  return { start, update };
};

// let cnt = 0;
// const updatePosition = () => {
//   mousePositions[cnt] = mouse;
//   const { res } = updateData;

//   position.forEach((pos, i) => {
//     const mouseIndex = Math.trunc(pos[2] * N);
//     const prevPos = mousePositions[mouseIndex];
//     const curPos =
//       mouseIndex === cnt
//         ? prevPos
//         : mouseIndex === N - 1
//         ? mousePositions[0]
//         : mousePositions[mouseIndex + 1];
//     const p = (pos[2] * N) % 1;
//     const mousePos = mix(prevPos, curPos, p);
//     const normalizedPos = [
//       pos[0] + (mousePos[0] / res[0] - 0.5) * 2,
//       pos[1] + (mousePos[1] / res[1] - 0.5) * 2,
//       pos[2],
//     ];
//     posBuffer.subdata(normalizedPos, i * 3 * 4);
//   });

//   cnt = (cnt + 1) % N;
// };
